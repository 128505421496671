<template>
    <div id="app">
        <!--        <keep-alive>-->
        <router-view/>
        <!--        </keep-alive>-->
    </div>

</template>
<script>
    export default {
        name: 'app',
        data() {
            return {}
        },
        metaInfo () {
            return {
                title: this.$store.state.Name
            }
        },
        created() {
            this.official()
        },
        methods: {
            official() {
                this.$axios.post('/api/h5/init')
                    .then(res => {
                        console.log(res);
                        this.$store.commit('H5_name', res.data.data.name)
                    })
            }
        },
    }
</script>
<style>

</style>
