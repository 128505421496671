import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Name: ''
    },
    getters: {},
    mutations: {
        H5_name(state, name) {
            state.Name = name
        }
    },
    actions: {},
    modules: {}
})
