import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/Index'),
        redirect: '/library',
        children: [
            {
                path: '/library',
                name: 'library',//书库
                component: () => import('../views/LibraryView/Library')
            },
            {
                path: '/bookcase',
                name: 'bookcase', //书架
                component: () => import('../views/BookcaseView/Bookcase')
            },
            {
                path: '/welfare',
                name: 'welfare',//福利
                component: () => import('../views/WelfareView/Welfare')
            },
            {
                path: '/my',
                name: 'my',//我的
                component: () => import('../views/MyView/My')
            },
        ]
    },
    {
        path: '/search',
        name: 'search', //书架
        component: () => import('../views/BookcaseView/Search')
    },
    {
        path: '/system',
        name: 'system',//我的消息
        component: () => import('../views/MyView/System')
    },
    {
        path: '/personality',
        name: 'personality',//个性签名
        component: () => import('../views/MyView/Personality')
    },
    {
        path: '/binding',
        name: 'binding',//账号绑定和设置
        component: () => import('../views/MyView/Binding')
    },
    {
        path: '/replace',
        name: 'replace',//查看已绑定的手机号
        component: () => import('../views/MyView/Replace')
    },
    {
        path: '/replacephone',
        name: 'replacephone',//进行手机号绑定
        component: () => import('../views/MyView/ReplacePhone')
    },
    {
        path: '/replacebinding',
        name: 'replacebinding',//更换手机号
        component: () => import('../views/MyView/ReplaceBinding')
    },
    {
        path: '/invitation',
        name: 'invitation',//邀请好友
        component: () => import('../views/MyView/Invitation')
    },
    {
        path: '/rules',
        name: 'rules',//邀请好友-活动规则
        component: () => import('../views/MyView/Rules')
    },
    {
        path: '/fond',
        name: 'fond',//阅读喜好
        component: () => import('../views/MyView/Fond')
    },
    {
        path: '/record',
        name: 'record',//阅读记录
        component: () => import('../views/MyView/Record')
    },
    {
        path: '/customer',
        name: 'customer',//联系客服
        component: () => import('../views/MyView/Customer')
    },
    {
        path: '/vip',
        name: 'vip',//vip 会员
        component: () => import('../views/MyView/Vip')
    },
    {
        path: '/wallet',
        name: 'wallet',//我的钱包
        component: () => import('../views/MyView/Wallet')
    },
    {
        path: '/topup',
        name: 'topup',//充值
        component: () => import('../views/MyView/Topup')
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',//我要提现
        component: () => import('../views/MyView/Withdrawal')
    },
    {
        path: '/subsidiary',
        name: 'subsidiary',//提现明细
        component: () => import('../views/MyView/Subsidiary')
    },
    {
        path: '/subsidiarydetails',
        name: 'subsidiarydetails',//提现详情
        component: () => import('../views/MyView/SubsidiaryDetails')
    },
    {
        path: '/progress',
        name: 'progress',//提现进度
        component: () => import('../views/MyView/Progress')
    },
    {
        path: '/account',
        name: 'account',//添加提现账户
        component: () => import('../views/MyView/Account')
    },
    {
        path: '/up',
        name: 'up',//设置
        component: () => import('../views/MyView/Up')
    },
    {
        path: '/systemdetails',
        name: 'systemdetails',//系统信息
        component: () => import('../views/MyView/SystemDetails')
    },
    {
        path: '/more',
        name: 'more',//更多
        component: () => import('../views/LibraryView/More')
    },
    {
        path: '/details',
        name: 'details',//小说详情
        component: () => import('../views/LibraryView/Details')
    },
    {
        path: '/bookreview',
        name: 'bookreview',//书评
        component: () => import('../views/LibraryView/BookReview')
    },
    {
        path: '/bookdetails',
        name: 'bookdetails',//书评详情
        component: () => import('../views/LibraryView/BookDetails')
    },
    {
        path: '/evaluation',
        name: 'evaluation',//书籍评价
        component: () => import('../views/LibraryView/Evaluation')
    },
    {
        path: '/reading',
        name: 'reading',//阅读
        component: () => import('../views/LibraryView/Reading')
    },
    {
        path: '/directory',
        name: 'directory',//目录
        component: () => import('../views/LibraryView/Directory')
    },
    {
        path: '/login',
        name: 'login',//登录
        component: () => import('../views/LoginView/Login')
    },
    {
        path: '/loginregistered',
        name: 'loginregistered', //注册
        component: () => import('../views/LoginView/LoginRegistered')
    },
    {
        path: '/loginforget',
        name: 'loginforget', //忘记密码
        component: () => import('../views/LoginView/LoginForget')
    },
    {
        path: '/logincode',
        name: 'logincode', //验证码登录
        component: () => import('../views/LoginView/LoginCode')
    },
    {
        path: '/agreement',
        name: 'agreement', //用户协议
        component: () => import('../views/LoginView/Agreement')
    },
    {
        path: '/privacy',
        name: 'privacy', //隐私政策
        component: () => import('../views/LoginView/Privacy')
    },

    {path: '*', redirect: '/',}

]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
